import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

//Tailwind
import './css/tailwind.css'

//axios
import axios from 'axios'
import VueAxios from 'vue-axios'

createApp(App)
    .use(VueAxios, axios)
    .use(store)
    .use(router)
    .mount('#app')
