import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "menu px-4 overflow-auto" }
const _hoisted_2 = { class: "menu-option flex gap-4 items-center cursor-pointer" }
const _hoisted_3 = { class: "py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["backdrop fixed h-full w-full bg-black bg-opacity-80 ease-linear duration-100 z-10", (_ctx.open) ? 'block' : 'hidden'])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["sidebar fixed top-0 w-3/4 h-full pt-16 bg-accent-color flex flex-col rounded-r-3xl text-white ease-linear duration-100 z-20", (_ctx.open) ? 'left-0' : '-left-3/4'])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuOptions, (menuOption, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: menuOption.url,
            key: index
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "icon w-4 h-4 bg-white",
                  style: _normalizeStyle('-webkit-mask-image: url('+menuOption.icon+'); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center;')
                }, null, 4),
                _createElementVNode("p", _hoisted_3, _toDisplayString(menuOption.label), 1)
              ])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ], 2)
  ], 64))
}