import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "toast-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toast-container fixed left-0 right-0 bg-gray-300 p-8 m-8 rounded-xl transition-all duration-600 shadow-2xl flex items-center justify-between z-10", _ctx.showToast ? 'bottom-0' : '-bottom-80']),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    style: _normalizeStyle({ cursor: _ctx.type === 'sems' || 'validation_error' ? 'pointer' : 'default' })
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.message), 1),
    (_ctx.type == 'ok')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "icon w-4 h-4 bg-primary-color",
          style: _normalizeStyle('-webkit-mask-image: url(/img/circle-check-solid.svg);')
        }, null, 4))
      : (_ctx.type == 'sems')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "icon w-4 h-4 bg-primary-color",
            style: _normalizeStyle('-webkit-mask-image: url(/img/circle-info-solid.svg);')
          }, null, 4))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "icon w-4 h-4 bg-accent-color",
            style: _normalizeStyle('-webkit-mask-image: url(/img/circle-exclamation-solid.svg);')
          }, null, 4))
  ], 6))
}