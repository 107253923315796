
import { defineComponent } from 'vue'

export default defineComponent({
    name: "SideBar",
    props:{
        menuOptions:{
            type: Array
        },
        open: {
            type: Boolean,
            default: false
        }
    }
    
})
