
import { defineComponent } from "vue";

export default defineComponent({
  name: "Toast",
  data() {
    return {
      showToast: false,
      message: "",
      type: "",
      duration: 4000, // Duración predeterminada del toast
    };
  },
  methods: {
    show: function (message: string, type: string) {
      this.message = message;
      this.type = type;
      this.showToast = true;

      if (this.type === "validation_error") {
        this.duration = 10000; // Cambia la duración a 10 segundos para 'validation_error'
      }

      if (this.type === "sems") {
        this.duration = 6000; // Cambia la duración a 10 segundos para 'validation_error'
      }

      setTimeout(() => {
        this.showToast = false;
        this.duration = 4000; // Restaura la duración predeterminada
      }, this.duration);
    },
    handleClick() {
      if (this.type === "sems") {
        this.$router.push("/mi-cuenta"); 
      }
      if (this.type === "validation_error") {
        window.open('https://api.whatsapp.com/send?phone=5491153501666&text=Hola! tengo problemas para validar mi credencial');
      }
    },
  },
});
