import { createStore } from "vuex";

export default createStore({
  state: {
    flags: {
      descuentoVerificado: false,
    },
    order: {
      status: "pending",
      items: [],
      products: [] as any[any],
    },
  },
  getters: {},
  mutations: {
    setItems(state, data) {
      state.order.items = data;
    },
    addProduct(state, newProduct) {
      let alreadyInCart = 0;
      if (state.order.products.length > 0) {
        state.order.products.forEach((product: any, index: number) => {
          if (product.variation_id == newProduct.selectedVariation.id) {
            alreadyInCart = 1;
            state.order.products[index].quantity++;
          }
        });
      }

      if (alreadyInCart == 0) {
        state.order.products.push({
          variation_id: newProduct.selectedVariation.id,
          name: newProduct.name + " " + newProduct.selectedVariation.name,
          quantity: 1,
          item_price: newProduct.selectedVariation.price,
          item_discount: newProduct.selectedVariation.discount,
        });
      }
    },
    addProduct2(state, newProduct) {
      let alreadyInCart = 0;
      if (state.order.products.length > 0) {
        state.order.products.forEach((product: any, index: number) => {
          if (product.variation_id == newProduct.id) {
            alreadyInCart = 1;
            state.order.products[index].quantity++;
          }
        });
      }

      if (alreadyInCart == 0) {
        state.order.products.push({
          variation_id: newProduct.id,
          name: newProduct.name,
          quantity: 1,
          item_price: Number(newProduct.price),
          item_discount: newProduct.discount !== undefined ? newProduct.discount : null,
        });
      }
    },
  },
  actions: {},
  modules: {},
});
