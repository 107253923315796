
export default {
  name: "Button",
  props: {
    type: {
			type: String,
      default: "squared" 
		}
  }
};
