
//custom components
import Button from "@/components/Button.vue";
import Toast from "@/components/Toast.vue";

// Swiper
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

// Utils
import utilities from "../utilities";

import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "Home",
  components: {
    Button,
    Toast,
  },
  data() {
    return {
      basePrice: 0,
      banners: [] as any[any],
      products: {} as any[any],
      isHover: {} as any[any],
      validated: false,
    };
  },
  async mounted() {
    //get config data
    let configData = (await utilities.getItems(
      "config/1?fields=*.*.*.*"
    )) as any;

    //get base price
    this.basePrice = configData.credential_price;

    //get banners
    this.banners = configData.banners;

    //get featured products
    this.products = configData.featured_products;

    //set default variation
    this.products.forEach((product: any, productIndex: number) => {
      this.products[productIndex].selectedVariation =
        this.products[productIndex].variations[0];
    });

    //validación credenciales ok
    let valid = await (await utilities.isValidated()).isValid;
    this.validated = valid;

    //create banners swiper
    const bannersOpts = {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      speed: 500,
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Autoplay, Navigation, Pagination],
    };

    setTimeout(() => {
      const swiper = new Swiper(this.$refs.banners as any, bannersOpts);
    }, 100);
  },
  methods: {
    setVariation(productIndex: number, variarionIndex: number) {
      this.products[productIndex].selectedVariation =
        this.products[productIndex].variations[variarionIndex];
    },
    async addProduct(product: any) {
      //let valid = await (await utilities.isValidated()).isValid;
      const toast = this.$refs.toast as any;
      if (product.no_stock != null && product.no_stock === true) {
        toast.show("Lo sentimos, este producto no está disponible");
        return;
      }
      if (
        product.selectedVariation.status == "published" ||
        product.selectedVariation.stock > 0
      ) {
        console.log(product.selectedVariation)
        this.$store.commit("addProduct", product);
        toast.show("Agregaste un producto al carrito");
      } else {
        toast.show("Color no disponible");
      }

    },
    setHover(productIndex: number) {
      this.isHover[productIndex] = true;
    },
    unsetHover(productIndex: number) {
      this.isHover[productIndex] = false;
    },
  },
});
