//axios
import axios from 'axios'
//router
import router from './router';
//upload
import { upload } from 'upload';

//mercado pago
//import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'


// set server url

const server = (window.location.host == 'test.reprocard.com' || process.env.NODE_ENV === 'development')
  ? window.location.protocol + '//test.admin.reprocard.com/api'
  : window.location.protocol + '//admin.reprocard.com/api'

const laravel_server = (window.location.host == 'test.reprocard.com' || process.env.NODE_ENV === 'development')
  ? window.location.protocol + '//test.new.admin.reprocard.com/api'
  : window.location.protocol + '//new.admin.reprocard.com/api'

const utils = {
  getItems: async function (request: string, token = '') {

    const endpoint = (token != '')
      ? server + '/items/' + request + '&access_token=' + token
      : server + '/items/' + request

    const response = await axios.get(endpoint)
      .catch(error => {
        console.log(error.response)
        //router.push('/disconnected')
        throw 'Error de Servidor'
      })

    const items = response.data.data

    return items
  },
  auth: async function (email: string, password: string, onError: any, continueTo: string) {
    const endpoint = server + '/auth/authenticate'

    const response = await axios.post(endpoint, { 'email': email, 'password': password })
      .catch(error => {
        const errorCode = error.response.data.error.code

        if (errorCode == 100) {
          onError('Datos Invalidos')
        } else if (errorCode == 103) {
          onError('Cuenta Inactiva')
        } else {
          onError('Hubo un error. Intente nuevamente.')
        }
      }) as any

    if (response && response.status == 200) {
      localStorage.setItem('token', response.data.data.token)
      if (continueTo != '') {
        router.push('/' + continueTo)
      } else {
        router.push('/')
      }
    }
  },
  checkAuth: async function (backTo = '') {

    if (localStorage.getItem('token')) {
      // gte current route
      const currentRoute = router.currentRoute.value.path

      // get token
      const token = localStorage.getItem('token') as string

      const response: any[any] = await axios.get(
        server + '/users/me?access_token=' + token,
      )
        .catch(error => {
          if (error.response.status == 401) {
            //remove old token
            localStorage.removeItem('token')

            // push to log in back
            if (currentRoute !== '/login') {
              if (backTo != '') {
                router.push('/login?to=' + backTo)
              }
            }
          } else {
            console.log('Error de servidor')
          }
        })


      // VALID TOKEN
      return {
        token: token,
        data: response.data.data
      }
    } else {
      // no token
      if (backTo != '') {
        router.push('/login?to=' + backTo)
      }
    }
  },
  checkForm: function (form: FormData) {
    let empyInputs = true

    Array.from(form).forEach((input: any[any]) => {
      if (input.required && input.value === '' && empyInputs) {
        console.log(input.name)
        empyInputs = false
      }
    })

    return empyInputs
  },
  createAccount: async function (firstName: string, lastName: string, email: string, password: string, onError: any) {
    const endpoint = server + '/items/users'
    const response = await axios.post(endpoint,
      {
        "status": "active",
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "password": password
      }
    ).catch(error => {
      onError(error.response.data.error.message)
      throw error.response.data.error.message
    })

    return 'ok'
  },
  resetPassword: async function (email: string) {
    const endpoint = server + '/auth/password/request'
    const response = await axios.post(endpoint,
      {
        "email": email,
        "reset_url": "https://mydomain/passwordreset"
      }
    )

    if (response.status == 200) {
      return 'sent'
    } else {
      return 'error'
    }
  },
  setPassword: async function (password: string, token: string) {
    const endpoint = server + '/auth/password/reset'
    const response = await axios.post(endpoint,
      {
        "token": token,
        "password": password
      }
    )

    if (response.status == 200) {
      return 'ok'
    } else {
      return 'error'
    }
  },
  uploadFile: async function (file: File, token: string) {

    const response = await upload(
      server + '/files?access_token=' + token,
      {
        upfile: file as File,
      }
    ).catch(error => {
      return error.response
    })

    const data = JSON.parse(response.data as any)

    return data.data
  },
  ocrFile: async function (file: string) {

    const response = await axios.get(server + '/custom/pdf-content-extractor?file=' + file)
      //const response = await axios.get('https://backend-test.tango.reprocard.com/pdf-content-extractor?file=' + file)
      .catch(error => {
        return error.response
      })

    return Promise.resolve(response).then((data) => {
      const item = data.data
      return item
    })

  },
  qrDecode: async function (file: string) {

    const postData = {
      "pdfUrl": file
    }

    //const response = await axios.post('http://localhost:8081/qr-decoder', postData)
    const response = await axios.post('https://backend-test.tango.reprocard.com/qr-decoder', postData)

    return Promise.resolve(response).then((data) => {
      return data.data
    })
  },
  createOrder: async function (orderData: any, token: string) {
    const endpoint = server + '/items/orders?fields=*,items.*&access_token=' + token

    const response = await axios.post(endpoint, orderData)

    return response.data.data
  },
  createPaymentHash: async function (orderData: any) {
    const endpoint = server + '/custom/prisma/decidir/pay'

    const response = await axios.post(endpoint, orderData)

    return response.data
  },
  getAppointments: async function (request: string, token = '') {

    const endpoint = 'https://backend-test.tango.reprocard.com/appointments'

    const response = await axios.get(endpoint)
      .catch(error => {
        console.log(error.response)
        //router.push('/disconnected')
        throw 'Error de Servidor'
      })

    const items = response.data

    return items
  },
  subscribe: async function (email: string, onError: any) {
    //const endpoint = 'http://127.0.0.1:8000/api/newsletter/' 
    const endpoint = laravel_server + '/newsletter'
    const response = await axios.post(endpoint,
      {
        "email": email,
      }
    ).catch(error => {
      if (error.response.status == 422) {
        // validation errors
        throw (error.response.data.errors['email'])
      }
      onError(error.response.data.error.message)
      throw error.response.data.error.message
    })
    // await new Promise(resolve => setTimeout(resolve, 1000));
    // const response = {"success":true,"data":{"message":"Te suscribiste existosamente!"}}

    return response.data.message

  },
  getProducts: async function (category: any) {
    let endpoint = laravel_server;

    console.log('category', category)
    if (category == null) {
      endpoint + '/products'
    } else {
      endpoint = endpoint + '/products?category=' + category
      console.log('endpoint', endpoint)
    }


    const response = await axios.get(endpoint)
      .catch(error => {
        console.log(error.response)
        //router.push('/disconnected')
        throw 'Error de Servidor'
      })

    const items = response.data

    console.log('data', items)

    return items
  },
  validateUser: async function (link: string, fullname: string, expiration_date: string, file_id: number) {
    const token = localStorage.getItem('token') as string
    const endpoint = laravel_server + '/users/validation'
    const response = await axios.post(endpoint,
      {
        "token": token,
        "link": link,
        "fullname": fullname,
        "expiration_date": expiration_date,
        "file_id": file_id
      }
    ).catch(error => {

      if (error.status == 422) {
        throw error.data.errors.link[0]
      }
      console.log(error.response)
      //router.push('/disconnected')
      throw 'Error en el proceso de validación, intentá nuevamente o comunicate por WhatsApp.'
    })

    if (response.status == 200) {
      return 'ok'
    } else {
      return 'error'
    }
  },
  isValidated: async function () {

    if (localStorage.getItem('token')) {

      // get token
      const token = localStorage.getItem('token') as string

      const response: any[any] = await axios.get(
        laravel_server + '/users/validation?access_token=' + token,
      ).catch(error => {
        return false
      })
      if (response) {
        return {
          isValid: true,
          data: response.data
        }
      }
      return {
        isValid: false
      }



    } else {
      return {
        isValid: false
      }
    }
  },
  paymentMP: async function (title: string, quantity: number, price: number, track_id: string, order_id: number) {

    const orderData = {
      title: title,
      quantity: quantity,
      price: price,
      shipping_track_id: track_id,
      order_id: order_id
    }

    const response: any[any] = await axios.post("https://mercadopago.tango.reprocard.com/create_preference",
      {
        "title": orderData.title,
        "quantity": orderData.quantity,
        "price": orderData.price,
        "track_id":orderData.shipping_track_id,
        "order_id":orderData.order_id
      }).catch(error => {
        console.log("error al obtener preferencia")
        return "ERRRORRRRR"  
      }

      );
    console.log("reponse mp" + response)    
    return response.data;

  }
}

export default utils

